import { HttpRequest, HttpHandlerFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { AuthService } from "../data-access/auth/auth.service";

export function AuthInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const authService = inject(AuthService);

  const clonedRequest = req.clone({
    setHeaders: {
      Authorization: authService.getBearerToken()
    }
  })

  return next(clonedRequest)
}