import { Routes } from '@angular/router';
import { authorizationGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./shared/ui/layout.component'),
    children: [
      {
        title: 'GI - Inicio',
        path: 'home',
        loadComponent: () => import('./home/home.component'),
      },
      {
        title: 'GI - Chat',
        path: 'chat/:id',
        loadComponent: () => import('./chat/chat.component'),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
    canActivate: [authorizationGuard()],
    canActivateChild: [authorizationGuard()],
  },
  {
    title: 'GI - Login',
    path: 'login',
    loadComponent: () => import('./auth/login/login.component'),
  },
  {
    title: 'GI - Login',
    path: 'oauth-callback',
    loadComponent: () =>
      import('./auth/oauth-callback/oauth-callback.component'),
  },
  {
    path: '**',
    loadComponent: () => import('./shared/ui/not-found.component'),
  },
];
