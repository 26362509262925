import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { WINDOW } from "../../app.config";
import { AuthService } from "../data-access/auth/auth.service";

export function authorizationGuard(): CanActivateFn {
  return () => {
    const router = inject(Router);
    const authService = inject(AuthService);
    const window = inject(WINDOW);

    const myLocation: Location = window.location;
    let isLoggedIn!: boolean;


    isLoggedIn = authService.isLoggedIn();
    if (!isLoggedIn) {
      router.navigate(['/login', { origin_uri: myLocation.pathname }]);
    }


    return isLoggedIn;
  }
}