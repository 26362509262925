import { Injectable, inject } from '@angular/core';
import moment from 'moment';
import { ACCESS_TOKEN_JWT, ACCESS_TOKEN_USERID, TOKEN_EXPIRATION_TIME } from '../../utils/auth.utils';
import { WINDOW } from '../../../app.config';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private window = inject(WINDOW);
  sessionStorage: Storage = this.window.sessionStorage;

  isLoggedIn(): boolean {
    return moment().isBefore(moment(this.sessionStorage.getItem(TOKEN_EXPIRATION_TIME), 'X'));
  }

  retrieveCDSID(): string {
    return this.getJwtData()['CommonName'];
  }

  retrieveFullName(): string {
    return `${this.getJwtData()['givenname']} ${this.getJwtData()['surname']}`;
  }

  isGenericId(): boolean {
    return this.getJwtData()['empcode'] === 'G';
  }

  storeJWT(userIdKeyId: string, jwtTokenKeyId: string, token: string): void {
    this.sessionStorage.setItem(userIdKeyId, atob(token.split('.')[1])); // only store payload section from JWT
    this.sessionStorage.setItem(jwtTokenKeyId, token);
  }

  storeTokenExpirationTime(token: string): void {
    const payload = atob(token.split('.')[1]);
    this.sessionStorage.setItem(TOKEN_EXPIRATION_TIME, JSON.parse(payload).exp);
  }

  retrieveJwtToken(): string {
    return <string>this.sessionStorage.getItem(ACCESS_TOKEN_JWT);
  }

  getBearerToken(): string {
    return `Bearer ${this.retrieveJwtToken()}`;
  }

  private getJwtData(): any {
    const idTokenString = this.sessionStorage.getItem(ACCESS_TOKEN_USERID);
    if (idTokenString) {
      return JSON.parse(idTokenString);
    }
    return {};
  }

}
